import React from "react"

import Layout from "../components/layout"

import ImageMeta from "../components/ImageMeta"

import { SharedStateProvider } from "../components/SharedState/PageSharedState"

import SEO from "../components/seo"

import Button from "@bit/azheng.joshua-tree.button"
import TheaterVideo from "../components/Theater/TheaterVideo"
import { Link } from "gatsby"

const AcademicAdvantage = () => {
  let language = "en"

  return (
    <SharedStateProvider>
      <Layout pageTitle="procedure-page" language={language}>
        <SEO
          title="2020 Virtual Academic College & Career Fair"
          description="Learn about the 2020 Virtual Academic College & Career Fair in Mount Pleasant, SC, where middle and high school students can learn about careers in the medical field, college admissions, and more."
        />
        {/* <Button 
          buttonText={language === "es" ? "ATRÁS" : "BACK"}
          goBack
          /> */}

        <div className="joshua-tree-content program-page">
          <div className="columns top-section white-back has-text-centered">
            <div className="column">
              <div className="program-banner">
                <ImageMeta
                  cloudName="nuvolum"
                  publicId="Programs/academic-advantage-border2.svg"
                  width="auto"
                  className="background"
                ></ImageMeta>

                <ImageMeta
                  cloudName="nuvolum"
                  publicId="Programs/academic-advantage-badge.svg"
                  width="auto"
                  className="badge"
                ></ImageMeta>
              </div>
            </div>
          </div>

          <section className="body-section">
            <div className="columns">
              <div className="column is-2"></div>
              <div className="column">
                <h1 className="has-text-centered">
                  2020 Virtual Academic Advantage College & Career Fair
                </h1>
                <p>
                  Our VIRTUAL Academic Advantage College & Career Fair was a
                  huge success, and we were so thankful to everyone who was able
                  to join us on April 28. Our featured speakers helped give an
                  overview of what it took for them to become the medical
                  professionals they are today, while our counselor gave
                  practical advice for the next steps students should take,
                  including college research and applications. We were also
                  pleased to help one student start saving for college by
                  awarding them with a $500 scholarship.
                </p>

                <p>
                  The resources below are designed to help students continue on
                  the path to becoming a healthcare professional. The entire
                  presentation is available as a video to review or watch for
                  the first time if you were unable to join us. We also have the
                  presentation slides and links to learn more about local
                  colleges, universities, community organizations, and
                  nonprofits.
                </p>
              </div>
              <div className="column is-2"></div>
            </div>
          </section>

          {/* <section className="body-section program-video">
            <div className="columns">
            <div className="column is-3"></div>
              <div className="column">
                <TheaterVideo
                    videoUrl="https://youtu.be/bsVAM7IHaZ8?t=8"
                    controls={true}
                    playing={true}
                    overVideo={true}
                    hasWatchVideo={true}
                  >
                  <ImageMeta
                      cloudName="nuvolum"
                      publicId="OKC/DEV/okc-edu-video-image"
                      width="auto"
                      responsive
                      responsiveUseBreakpoints="true"
                    ></ImageMeta>
                  </TheaterVideo>
              </div>
                <div className="column is-3"></div>
            </div>
            </section> */}

          <section className="body-section">
            <div className="columns">
              <div className="column is-2"></div>
              <div className="column left">
                <h3 className="left-heading" style={{ marginBottom: "1rem" }}>
                  2020 Virtual Academic Advantage College & Career Fair
                </h3>

                <TheaterVideo
                  videoUrl="https://youtu.be/bsVAM7IHaZ8?t=8"
                  controls={true}
                  playing={true}
                  overVideo={true}
                  hasWatchVideo={true}
                ></TheaterVideo>
              </div>
              <div className="column is-1"></div>
              <div className="column is-8 right color-list color-back">
                <h3>Featured Speakers:</h3>
                <ul>
                  <li>
                    <strong>
                      <Link to="/matthew-r-barefoot-dds-md/">
                        Matthew R. Barefoot, DDS, MD
                      </Link>
                    </strong>
                    <br />
                    Oral Surgeon at Oral Surgery & Dental Implant Specialists
                    South Carolina
                  </li>
                  <li>
                    <strong>
                      <a
                        target="_blank"
                        href="https://kelleybraces.com/meet-our-orthodontists/"
                      >
                        Roberta Gardner, DMD
                      </a>
                    </strong>
                    <br />
                    Orthodontist at Gardner Orthodontics
                  </li>
                  <li>
                    <strong>
                      <a
                        target="_blank"
                        href="https://smilingoakdentistry.com/meet-the-staff/"
                      >
                        Jeff Gardner, DMD, MAGD
                      </a>
                    </strong>
                    <br />
                    Dentist at Smiling Oak Dentistry
                  </li>
                  <li>
                    <strong>
                      <a
                        target="_blank"
                        href="https://www.ambanes.com/our-doctors/dr-matthew-castele/"
                      >
                        Matthew T. Castele, DMD
                      </a>
                    </strong>
                    <br />
                    Dentist Anesthesiologist at Ambulatory Anesthesia Associates
                  </li>
                </ul>
                <h3>Counselor:</h3>
                <ul>
                  <li>
                    <strong>Pamela Robinson</strong>
                    <br />
                    Director of School Counseling at Oceanside Collegiate
                    Academy
                  </li>
                  <ul>
                    <li>
                      Email:{" "}
                      <a href="mailto:probinson@landsharks.org">
                        probinson@landsharks.org
                      </a>
                    </li>
                  </ul>
                </ul>
              </div>
              <div className="column is-3"></div>
            </div>
          </section>

          <section className="body-section last">
            <div className="columns">
              <div className="column is-2"></div>
              <div className="column left">
                <p>
                  <strong>Education</strong>
                </p>
                <ul>
                  <li>
                    <strong>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="/OSSC/DEV/200423_OSSC_2020_Presentation_Master_FINAL.pdf"
                      >
                        Academic Advantage Full Presentation
                      </a>
                    </strong>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href="https://www.claflin.edu/admissions-aid"
                    >
                      Claflin University
                    </a>
                  </li>
                  <ul>
                    <li>
                      Admissions Questions: Contact Sodelene Laurent at{" "}
                      <a href="mailto:Slaurent@claflin.edu">
                        Slaurent@claflin.edu
                      </a>
                      , Paula Payton at{" "}
                      <a href="mailto:pcpayton@claflin.edu">
                        pcpayton@claflin.edu
                      </a>
                      , or for transfers Ebony Douglas at{" "}
                      <a href="mailto:ebony.james@claflin.edu">
                        ebony.james@claflin.edu
                      </a>{" "}
                    </li>
                    <li>
                      <a target="_blank" href="https://youtu.be/2ZyLefztRAw">
                        Watch Video
                      </a>
                    </li>
                  </ul>
                  <li>
                    <a target="_blank" href="https://www.coastal.edu/">
                      Coastal Carolina University
                    </a>
                  </li>
                  <ul>
                    <li>
                      Admissions Questions:{" "}
                      <a
                        target="_blank"
                        href="https://www.coastal.edu/admissions/staff/"
                      >
                        Find an Admissions Counselor
                      </a>{" "}
                      or call Merit Awards at{" "}
                      <a href="tel:+18002777000">800-277-7000</a>.
                    </li>
                    <li>
                      <a
                        target="_blank"
                        href="/OSSC/DEV/2020_Travel_Piece_PDF.pdf"
                      >
                        Learn More
                      </a>
                    </li>
                  </ul>
                  <li>
                    <a target="_blank" href="https://go.citadel.edu/">
                      The Citadel
                    </a>
                  </li>
                  <ul>
                    <li>
                      Admissions Questions: Contact Lisa Weber at{" "}
                      <a href="mailto:Lisa.Weber@citadel.edu">
                        Lisa.Weber@citadel.edu
                      </a>{" "}
                    </li>
                  </ul>
                  <li>
                    <a
                      target="_blank"
                      href="https://dentistry.musc.edu/programs/prospective"
                    >
                      The Medical University of South Carolina - James B.
                      Edwards College of Dental Medicine
                    </a>
                  </li>
                  <ul>
                    <li>
                      Admissions Questions: Contact Lauren Chapman, College of
                      Dental Medicine Admissions Specialist at{" "}
                      <a href="mailto:chapmala@musc.edu">chapmala@musc.edu</a>{" "}
                      or Pearl M. Givens, Student Services Coordinator, Office
                      of Diversity at{" "}
                      <a href="mailto:givensp@musc.edu">givensp@musc.edu</a>{" "}
                    </li>
                    <li>
                      <a
                        target="_blank"
                        href="/OSSC/DEV/JBE_Brochure_update_post_Haley.pdf"
                      >
                        Learn More
                      </a>
                    </li>
                  </ul>
                  <li>
                    <a target="_blank" href="https://www.mscok.edu/">
                      Murray State College
                    </a>
                  </li>
                  <ul>
                    <li>
                      Admissions Questions: Email{" "}
                      <a href="mailto:outreach@mscok.edu">outreach@mscok.edu</a>{" "}
                    </li>
                  </ul>
                  <li>
                    <a target="_blank" href="https://www.tridenttech.edu/">
                      Trident Technical College
                    </a>
                  </li>
                  <ul>
                    <li>
                      Admissions Questions: Contact Jean Brooks, Workforce
                      Military Recruiter at{" "}
                      <a href="mailto:jeanbrooks@tridenttech.edu">
                        jeanbrooks@tridenttech.edu
                      </a>{" "}
                      or <a href="tel:+18435746379">843-574-6379</a>
                    </li>
                    <li>
                      Learn more about{" "}
                      <a
                        target="_blank"
                        href="/OSSC/DEV/Trident_College_-_Free_Tuition_Flier_Summer.pdf"
                      >
                        Free Summer Tuition
                      </a>{" "}
                      and{" "}
                      <a
                        target="_blank"
                        href="/OSSC/DEV/Trident_College_-_RC20-016_SummerTransient_FLIER_NO_CROPS.pdf"
                      >
                        Summer Classes
                      </a>
                    </li>
                  </ul>
                  <li>
                    <a target="_blank" href="https://www.uscb.edu/">
                      University of South Carolina Beaufort
                    </a>
                  </li>
                  <ul>
                    <li>
                      Admissions Questions: Contact Matt Cash, Assistant
                      Director of Admission at{" "}
                      <a href="mailto:cashmj@uscb.edu">cashmj@uscb.edu</a> or{" "}
                      <a href="tel:+18432088037">843-208-8037</a>
                    </li>
                  </ul>
                </ul>
              </div>
              <div className="column is-1"></div>
              <div className="column">
                <p>
                  <strong>Nonprofits & Community Organizations</strong>
                </p>
                <ul>
                  <li>
                    <a href="https://www.charlestonhabitat.org/">
                      Charleston Habitat for Humanity
                    </a>
                  </li>
                  <ul>
                    <li>
                      Questions: Contact Kristen Williams at{" "}
                      <a href="mailto:kristen@charlestonhabitat.org">
                        kristen@charlestonhabitat.org
                      </a>
                    </li>
                    <li>
                      <a href="/OSSC/DEV/2020_CHFH_About_Us.pdf">Learn More</a>
                    </li>
                  </ul>
                  <li>
                    <a href="https://www.wingsforkids.org/">Wings for Kids</a>
                  </li>
                  <ul>
                    <li>
                      Questions: Contact{" "}
                      <a href="mailto:hr@wingsforkids.org">
                        hr@wingsforkids.org
                      </a>
                    </li>
                  </ul>
                </ul>
              </div>
              <div className="column is-3"></div>
            </div>
          </section>
        </div>
      </Layout>
    </SharedStateProvider>
  )
}

export default AcademicAdvantage
